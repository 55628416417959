<template>
  <div>
    <ik-data-table
      ref="table"
      :entity-name="$t('rawMaterial')"
      :model="model"
      :use-add-action="false"
    >
      <template
        #footer="{selectItems}"
      >
        <v-btn
          color="primary"
          elevation="0"
          @click="newAdd"
        >
          <v-icon
            class="mt-1"
            left
          >
            mdi-plus
          </v-icon>
          {{ $t('newRawMaterial') }}
        </v-btn>
      </template>
    </ik-data-table>
    <v-dialog
      v-model="newAddResource"
      max-width="600"
      persistent
    >
      <v-card class="pa-4">
        <div class="d-flex align-center mb-4">
          <div class="text-h3 font-weight-bold">
            {{ $t('newRawMaterial') }}
          </div>
          <v-spacer/>
          <div>
            <v-btn
              icon
              @click="closeDialog"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <div class="text-h4 mb-2">
          {{ $t('rawMaterialName') }}
        </div>
        <div class="d-flex align-center mb-4">
          <v-text-field
            v-model="resourceName"
            append-icon="mdi-text-short"
            dense
            hide-details
            outlined
          />
          <v-icon
            class="mr-4 ml-4"
          >
            mdi-lead-pencil
          </v-icon>
        </div>
        <div class="text-h4 mb-2">
          {{ $t('defaultBarcode') }}
        </div>
        <div class="d-flex align-center mb-4">
          <v-text-field
            v-model="resourceCode"
            append-icon="mdi-text-short"
            dense
            hide-details
            outlined
          />
          <v-icon
            class="mr-4 ml-4"
          >
            mdi-barcode
          </v-icon>
        </div>
        <div class="text-h4 mb-2">
          {{ $t('unitPrice') }}
        </div>
        <div class="d-flex align-center mb-4">
          <v-text-field
            v-model="unitPrice"
            append-icon="mdi-text-short"
            dense
            hide-details
            outlined
          />
          <v-icon
            class="mr-4 ml-4"
          >
            mdi-cash
          </v-icon>
        </div>
        <template>
          <div class="mb-1">
            <div class="text-body-2 mt-4 font-weight-bold">
              {{ $t('unitList') }}
            </div>
          </div>

          <div
            v-for="(a,index) in resourceList"
            :key="a.key"
            class="d-flex align-center mb-1"
          >
            <v-form
              ref="normalUnit"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                v-model="a.normalUnit"
                :placeholder="$t('unit')"
                dense
                hide-details
                outlined
              />
            </v-form>
            <div class="mr-2 ml-2">
              <v-icon>
                mdi-transfer-right
              </v-icon>
            </div>
            <v-form
              ref="nextLevelUnit"
              v-model="valid"
              lazy-validation
            >
              <v-text-field
                v-model="a.nextLevelUnit"
                :placeholder="$t('numberOf')"
                dense
                hide-details
                outlined
                step="1"
                type="number"
              />
            </v-form>
            <div class="mr-2 ml-2">
              <v-icon>
                mdi-power-on
              </v-icon>
            </div>
            <div class="mr-2">
              <v-form
                ref="note"
                v-model="valid"
                lazy-validation
              >
                <v-text-field
                  v-model="a.note"
                  :placeholder="$t('note')"
                  dense
                  hide-details
                  outlined
                />
              </v-form>
            </div>
            <div style="width: 60px">
              <v-btn
                :disabled="resourceList.length===0||(!a.normalUnit&&!a.nextLevelUnit&&!a.note)"
                class="ml-1"
                icon
                @click="resourceList.splice(index,1)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </template>
        <div class="mt-4 d-flex mr-4">
          <v-spacer/>
          <v-btn
            class="primary mr-2"
            @click="save"
          >
            {{ $t('save') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import Resource from '@/model/storage/Resource'
  import { IkDataTable } from 'metaflow-js'
  import IKUtils from 'innerken-js-utils'
  import Unit from '@/model/storage/Unit'
  import UnitSet from '@/model/storage/UnitSet'
  import { showSuccessMessage } from '@/common/utils'

  let counter = 0
  const defaultResource = {
    normalUnit: '',
    nextLevelUnit: '',
    note: '',
  }

  export default {
    name: 'ResourceListPage',
    components: {
      IkDataTable,
    },
    data: function () {
      return {
        valid: true,
        unitPrice: null,
        keyCode: '',
        resourceCode: null,
        resourceName: null,
        resourceList: [this.createDefaultAttribute()],
        newAddResource: null,
        model: Resource,
      }
    },
    computed: {
      needToAdd () {
        return this.resourceList.filter(it => it.normalUnit)
      },
    },
    watch: {
      resourceList: {
        deep: true,
        handler (val) {
          const emptyNode = val.filter(it => !it.normalUnit && !it.nextLevelUnit && !it.note).length
          if (emptyNode === 0) {
            this.resourceList.push(this.createDefaultAttribute())
          }
        },
      },
    },
    mounted () {
      window.onkeydown = async (event) => {
        if (event.keyCode !== 13) {
          this.keyCode += event.key
        } else {
          this.resourceCode = this.keyCode
          this.newAddResource = true
        }
      }
    },
    methods: {
      closeDialog () {
        this.newAddResource = false
        this.keyCode = ''
        this.$refs.note[0].reset()
        this.$refs.nextLevelUnit[0].reset()
        this.$refs.normalUnit[0].reset()
        this.resourceName = ''
        this.resourceCode = ''
        this.unitPrice = ''
      },
      async save () {
        let counter = 1
        const unitIdList = []
        for (const a of this.needToAdd) {
          a.value = counter++
          a.name = a.normalUnit
          a.nextLevelFactor = a.nextLevelUnit || 1
          unitIdList.push((await Unit.add(a)).id)
        }
        const unitSet = await UnitSet.add({
          name: this.resourceName,
          note: '',
          unitIds: unitIdList,
        })
        await Resource.add({
          name: this.resourceName,
          image: '',
          defaultBarcode: this.resourceCode,
          resourceUnitSetId: unitSet.id,
          lowestUnitPrice: this.unitPrice,
        })
        this.closeDialog()
        this.$refs.table.reload()
        showSuccessMessage()
      },
      createDefaultAttribute () {
        const obj = IKUtils.deepCopy(defaultResource)
        obj.key = counter++
        return obj
      },
      newAdd () {
        this.newAddResource = true
        this.resourceList = [this.createDefaultAttribute()]
      },
    },
  }
</script>

<style scoped>

</style>
